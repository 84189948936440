import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import { useQuery } from "@tanstack/react-query";
import { GetAllAdvertisement } from "../../../lib/api";
import {
  ShowLoadingAnimation,
  ShowNoDataAnimation,
} from "../../../components/common/Animation";
import { FaRegEdit } from "react-icons/fa";
import { constant } from "../../../lib/constant";

const AdvertisementList = () => {
  const navigate = useNavigate();
  const { isLoading, isPending, isError, data } = useQuery({
    queryKey: ["GetAllAdvertisement"],
    queryFn: () => GetAllAdvertisement(),
  });
  // console.log(data);

  const isAdvertisementData = data?.data?.advertisement;
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Advertisement List</h4>
      <div className="flex justify-end">
        <Link to={RouteNavigation.create_advertisement}>
          <button className="bg-gray-800 hover:bg-gray-600 text-white px-4 py-2 rounded-md">
            Create New
          </button>
        </Link>
      </div>
      {isLoading || isPending ? (
        <div className="w-44 flex justify-center m-auto">
          <ShowLoadingAnimation />
        </div>
      ) : (
        <div className="w-full">
          <div className="mt-5 border-2 rounded-lg">
            <div className="overflow-auto">
              <TableContainer>
                <Table>
                  <TableHead className="bg-gray-800 rounded-xl">
                    <TableRow>
                      <TableCell style={{ color: "white" }}>
                        Advertisement Name
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Preview</TableCell>
                      <TableCell style={{ color: "white" }}>Order</TableCell>
                      <TableCell style={{ color: "white" }}>
                        Department Name
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {!isPending && !isLoading && !isError && (
                    <TableBody>
                      {data?.data?.advertisement?.map((ad, index) => (
                        <TableRow key={index}>
                          <TableCell>{ad.AdvName}</TableCell>
                          <TableCell>
                            <img
                              className="w-16 h-16"
                              src={`${constant.image_render_url}${ad.AdvFileUrl}`}
                              alt="preview"
                            />
                          </TableCell>
                          <TableCell>{ad.AdvOrder}</TableCell>
                          <TableCell>{ad.depName}</TableCell>
                          <TableCell
                            onClick={() => {
                              navigate("/create-advertisement", {
                                state: { GetAdvertisement: ad },
                              });
                            }}
                          >
                            <div className="flex justify-center">
                              <FaRegEdit
                                className="cursor-pointer"
                                size={"20px"}
                                color={ad.Status === true ? "green" : "red"}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        {isError ||
          (isAdvertisementData?.length === 0 && (
            <div className="w-44 flex justify-center">
              <ShowNoDataAnimation />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdvertisementList;
