import React from "react";
import Lottie from "lottie-react";
import LoadingAnimation from "../../JSON/LoadingAnimation.json";
import ErrorLoadingAnimation from "../../JSON/ErrorLoadingAnimation.json";
import NoDataAnimation from "../../JSON/NoDataAnimation.json";

export const ShowLoadingAnimation = () => {
  return (
    <div>
      <Lottie animationData={LoadingAnimation} loop={true} autoplay={true} />
    </div>
  );
};

export const ShowErrorLoadingAnimation = () => {
  return (
    <div>
      <Lottie
        animationData={ErrorLoadingAnimation}
        loop={false}
        autoplay={true}
      />
    </div>
  );
};

export const ShowNoDataAnimation = () => {
  return (
    <div>
      <Lottie animationData={NoDataAnimation} loop={true} autoplay={true} />
    </div>
  );
};
