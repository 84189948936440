import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import { useQuery } from "@tanstack/react-query";
import { GetAllCompany } from "../../../lib/api";
import {
  ShowLoadingAnimation,
  ShowNoDataAnimation,
} from "../../../components/common/Animation";
import { FaRegEdit } from "react-icons/fa";
import { useState } from "react";

const Company = () => {
  const [searchString, getSearchString] = useState("");
  const { isLoading, isPending, isError, data } = useQuery({
    queryKey: ["GetAllCompany", searchString],
    queryFn: () => GetAllCompany(searchString),
  });
  const navigate = useNavigate();
  const isCompanyData = data?.data?.company;

  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Company List</h4>
      <div className="flex justify-end gap-2">
        <input
          type="search"
          placeholder="Search Company"
          onChange={(e) => getSearchString(e.target.value)}
          className="border-2 border-gray-200 focus:outline-none rounded-lg px-4 sm:w-auto"
        />
        <Link to={RouteNavigation.create_company}>
          <button className="bg-gray-800 hover:bg-gray-600 text-white px-4 py-2 rounded-md">
            Create New
          </button>
        </Link>
      </div>
      <div className="w-full">
        <div className="mt-5 border-2 rounded-lg">
          <TableContainer>
            <Table>
              <TableHead className="bg-gray-800 rounded-xl">
                <TableRow>
                  <TableCell style={{ color: "white" }}>Sl.No</TableCell>
                  <TableCell style={{ color: "white" }}>Client</TableCell>
                  <TableCell style={{ color: "white" }}>Key</TableCell>
                  <TableCell style={{ color: "white" }}>Valid Date</TableCell>
                  <TableCell style={{ color: "white" }}>Address</TableCell>
                  <TableCell style={{ color: "white" }}>Country</TableCell>
                  <TableCell style={{ color: "white", textAlign: "center" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isError &&
                  data?.data?.company?.map((company, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{company.CompanyName}</TableCell>
                      <TableCell>{company.LicenseKey}</TableCell>
                      <TableCell>{company.Validity}</TableCell>
                      <TableCell>{company.Address}</TableCell>
                      <TableCell>{company.Country}</TableCell>
                      <TableCell
                        className="cursor-pointer"
                        onClick={() =>
                          navigate("/create-company", {
                            state: { CompanyData: company },
                          })
                        }
                      >
                        <div className="flex justify-center">
                          <FaRegEdit
                            className="cursor-pointer"
                            size={"20px"}
                            color={company.Status === true ? "green" : "red"}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-44 flex justify-center">
          {isPending && isLoading && <ShowLoadingAnimation />}
          {isCompanyData?.length === 0 && <ShowNoDataAnimation />}
        </div>
      </div>
    </div>
  );
};
export default Company;
