import { Routes, Route } from "react-router-dom";
import * as Page from "./Pages";
import {
  SuperAdminProtectedPages,
  AdminProtectedPages,
  CoordinatorProtectedPages,
} from "./lib/context/ProtectedRoute";

export const RouteNavigation = {
  // Splash screen
  login: "/",

  //Super Admin Routes
  companies: "/companies",
  create_company: "/create-company",

  //Admin Routes
  homepage: "/home",
  counters: "/counters",
  create_counter: "/create-counter",
  departments: "/departments",
  create_department: "/create-department",
  users: "/users",
  create_user: "/create-user",
  advertisements: "/advertisements",
  create_advertisement: "/create-advertisement",
  settings: "/settings",
  report: "/token-detail-report",
  token_summary: "/token-summary",

  //Token Coodinator Routes
  available_counter: "/counter",
  generate_token: "/counter/:id",
  generate_custom_token: "/custom-counter/:id",

  //Pages
  page_not_found: "*",
};

const GenericRoutes = () => {
  return (
    <Routes>
      {/* Splash Screens */}
      <Route path={RouteNavigation.login} element={<Page.Login />} />
      <Route
        path={RouteNavigation.page_not_found}
        element={<Page.PageNotFound />}
      />

      {/* Super Admin Routes */}
      <Route
        to="/"
        element={
          <AdminProtectedPages>
            <CoordinatorProtectedPages>
              <Page.SuperAdminDashboard />
            </CoordinatorProtectedPages>
          </AdminProtectedPages>
        }
        children={
          <>
            <Route
              path={RouteNavigation.companies}
              element={<Page.Company />}
            />
            <Route
              path={RouteNavigation.create_company}
              element={<Page.CreateCompany />}
            />
          </>
        }
      />

      {/* Admin routes */}
      <Route
        to="/"
        element={
          <SuperAdminProtectedPages>
            <CoordinatorProtectedPages>
              <Page.Dashboard />
            </CoordinatorProtectedPages>
          </SuperAdminProtectedPages>
        }
        children={
          <>
            <Route
              path={RouteNavigation.homepage}
              element={<Page.HomePage />}
            />
            <Route
              path={RouteNavigation.counters}
              element={<Page.CounterList />}
            />
            <Route
              path={RouteNavigation.create_counter}
              element={<Page.CreateCounter />}
            />
            <Route
              path={RouteNavigation.departments}
              element={<Page.DepartmentList />}
            />
            <Route
              path={RouteNavigation.create_department}
              element={<Page.CreateDepartment />}
            />
            <Route path={RouteNavigation.users} element={<Page.UserList />} />
            <Route
              path={RouteNavigation.create_user}
              element={<Page.CreateUser />}
            />
            <Route
              path={RouteNavigation.advertisements}
              element={<Page.AdvertisementList />}
            />
            <Route
              path={RouteNavigation.create_advertisement}
              element={<Page.CreateAdvertisement />}
            />
            <Route
              path={RouteNavigation.settings}
              element={<Page.Settings />}
            />
            <Route
              path={RouteNavigation.report}
              element={<Page.DetailReport />}
            />
            <Route
              path={RouteNavigation.token_summary}
              element={<Page.Summary />}
            />
          </>
        }
      />

      {/* Token Coodinator Routes */}
      <Route
        to="/"
        element={
          <SuperAdminProtectedPages>
            <AdminProtectedPages>
              <Page.CoordinatorDashboard />
            </AdminProtectedPages>
          </SuperAdminProtectedPages>
        }
        children={
          <>
            <Route
              path={RouteNavigation.available_counter}
              element={<Page.AvailableCounter />}
            />
            <Route
              path={RouteNavigation.generate_token}
              element={<Page.Counter />}
            />
            <Route
              path={RouteNavigation.generate_custom_token}
              element={<Page.CustomCounter />}
            />
          </>
        }
      />
    </Routes>
  );
};
export default GenericRoutes;
