import axios from "axios";
import Cookies from "js-cookie";

export const GetToken = () => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  const getTokenURL = `token/tokenValue/${CompanyId}/${DepId}`;
  try {
    const response = axios.get(getTokenURL, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const GetHoldToken = () => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  const getHoldTokenURL = `token/onHold/${CompanyId}/${DepId}`;
  try {
    const response = axios.get(getHoldTokenURL, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const GetAllPendingToken = () => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  const allPendingTokenURL = `token/onPending/${CompanyId}/${DepId}`;
  try {
    const response = axios.get(allPendingTokenURL, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const GetTokenCall = (CounterID, status, tokenValue) => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  const TokenData = {
    CounterID: CounterID || undefined,
    status: status,
    tokenValue: tokenValue || undefined,
  };
  let getTokenCallUrl = `token/updateTokenStatus/${CompanyId}/${DepId}/${TokenData.CounterID}/${TokenData.status}`;
  if (
    TokenData.tokenValue &&
    TokenData.tokenValue !== "" &&
    TokenData.tokenValue !== "0"
  ) {
    getTokenCallUrl += `/${TokenData.tokenValue}`;
  }
  try {
    const response = axios.get(getTokenCallUrl, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const DeleteAllToken = () => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  // const UserId = Cookies.get("UserID");
  const deleteTokenUrl = `token/resetToken/${CompanyId}/${DepId}`;
  try {
    const response = axios.put(
      deleteTokenUrl,
      {},
      {
        headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const CustomTokenCall = (data) => {
  const customTokenCallUrl = "token/updateCustomTokenStatus";
  try {
    const response = axios.post(customTokenCallUrl, data, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const CompleteTokenStatus = (tokenValue) => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  let getTokenCallUrl = `token/tokenStatusUpdate/${CompanyId}/${DepId}/${tokenValue}`;
  try {
    const response = axios.get(getTokenCallUrl, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// token transfer
export const TokenTransfer = async (tokenValue, departmentid) => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");

  let tokenTransferUrl = `token/updateDepartment/${CompanyId}/${DepId}/${tokenValue}`;

  const requestBody = {
    newDepId: departmentid,
  };

  try {
    const response = await axios.put(tokenTransferUrl, requestBody, {
      headers: { Authorization: `Bearer ${Cookies.get("userToken")}` },
    });
    return response;
  } catch (error) {
    throw new Error(`Token transfer failed: ${error.message}`);
  }
};

export const GetDepartmentForCoordinator = () => {
  const GetDepartmentForCoordinatorURL = `department/web/${Cookies.get(
    "UserID"
  )}`;
  try {
    const response = axios.get(GetDepartmentForCoordinatorURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

// Token Recall
export const GetRecallToken = (data) => {
  const CompanyId = Cookies.get("CompanyId");
  const DepId = Cookies.get("DepID");
  const UserID = Cookies.get("UserID");
  const GetRecallTokenURL = `token/recall`;
  const requestBody = {
    depId: DepId,
    companyId: CompanyId,
    tokenId: data,
    userId: UserID,
  };
  try {
    const response = axios.put(GetRecallTokenURL, requestBody, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
      data: data,
    });
    return response;
  } catch (err) {
    return err;
  }
};
