import React, { useState } from "react";
import { FaBackspace } from "react-icons/fa";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import {
  GetToken,
  GetTokenCall,
  GetHoldToken,
  CompleteTokenStatus,
  TokenTransfer,
  GetDepartmentForCoordinator,
  GetRecallToken,
} from "../../../lib/api";

import { TokenReset } from "./TokenReset";
import PendingToken from "./PendingToken";
import TokenCall from "./TokenCallPopUp";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const Counter = () => {
  const location = useLocation();
  const { id } = useParams();
  const [tokenValue, setTokenValue] = useState("0");

  // console.log(tokenValue);

  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const [holdMenu, setHoldMenu] = useState(true);
  const [open, setOpen] = React.useState(false);
  const isCounterData = location?.state?.userCounterData;
  const CounterID = isCounterData?.CounterID;

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["GetToken"],
    queryFn: () => GetToken(CounterID),
    refetchInterval: 1000,
  });

  const counterOnCallToken = data?.data?.latestCounterCallToken?.find(
    (item) => item.CounterID === parseInt(id)
  );
  //  console.log(counterOnCallToken.TokenID);

  const handleButtonClick = (digit) => {
    if (tokenValue === "0") {
      setTokenValue(digit.toString());
    } else {
      setTokenValue(tokenValue + digit.toString());
    }
  };
  const { data: holdToken } = useQuery({
    queryKey: ["GetHoldToken"],
    queryFn: () => GetHoldToken(),
    refetchInterval: 1000,
  });
  const evenTokens = holdToken?.data?.tokensOnHold.filter(
    (item, index) => index % 2 === 0
  );
  const oddTokens = holdToken?.data?.tokensOnHold.filter(
    (item, index) => index % 2 === 1
  );
  const CounterButton = ({ num }) => {
    return (
      <button
        className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 text-center leading-50 text-base text-gray-700 m-1"
        onClick={() => handleButtonClick(num)}
      >
        {num}
      </button>
    );
  };
  // console.log("tokenValue",tokenValue, "a", counterOnCallToken?.LastOnCallToken)

  const handleTokenComplete = async () => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const getCurrentToken = await CompleteTokenStatus(tokenValue);
      if (getCurrentToken.status === 200) {
        toast.success("Token Completed", {
          position: "top-right",
          closeOnClick: true,
        });
        setTokenValue("0");
      } else {
        toast.error("Token Completion failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || "Token Completion Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      setOpen(false);
      Cookies.set("isHoldToken", false);
      refetch();
    }
    refetch();
    Cookies.set("isHoldToken", false);
  };

  const handleTokenCall = async (status) => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const getCurrentToken = await GetTokenCall(CounterID, status, tokenValue);
      if (getCurrentToken.status === 200) {
        toast.success("Token Called", {
          position: "top-right",
          closeOnClick: true,
        });
        setTokenValue("0");
      } else {
        toast.error("Token call failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || "Token call failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      setOpen(false);
      refetch();
    }
  };

  const handleHoldTokenCalling = (value) => {
    setTokenValue(value.TokenValue);
    Cookies.set("isHoldToken", true);
    handleClickOpen();
    refetch();
  };
  const handleClickOpen = () => {
    setOpen(true);
    refetch();
  };

  /// Token transfer
  const [openTransferToken, setOpenTransferToken] = useState(false);

  const handleTransferClick = () => {
    setOpen(false);
    setOpenTransferToken(true);
  };

  const { data: allDepartment } = useQuery({
    queryKey: ["GetDepartmentForCoordinator"],
    queryFn: () => GetDepartmentForCoordinator(),
  });

  console.log(allDepartment?.data?.department);

  // token transfer
  const handleTransfer = async (depID) => {
    const tokenToTransfer =
      tokenValue > 0 ? tokenValue : counterOnCallToken?.LastOnCallToken;

    if (!tokenToTransfer) {
      toast.error("No token value available for transfer.", {
        position: "top-right",
      });
      return;
    }

    try {
      const response = await TokenTransfer(tokenToTransfer, depID);
      if (response.status === 200) {
        toast.success("Token Transfer Completed", {
          position: "top-right",
          closeOnClick: true,
          autoClose: 5000,
        });
        setOpenTransferToken(false);
      } else {
        toast.error("Token Transfer Failed", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Token Transfer Failed", {
        position: "top-right",
      });
    }
  };

  // Token recall

  const handleRecallToken = async () => {
    if (isLoadingCall) return;
    setIsLoadingCall(true);
    try {
      const recallTokenResponse = await GetRecallToken(
        counterOnCallToken.TokenID
      );
      if (recallTokenResponse.status === 200) {
        toast.success("Token Recalled", {
          position: "top-right",
          closeOnClick: true,
        });
        // setTokenValue(counterOnCallToken?.LastOnCallToken || "0");
      } else {
        toast.error("Token Recall failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Token Recall failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoadingCall(false);
      refetch();
    }
  };

  return (
    <div className="max-w-[1024px] m-auto select-none px-4 py-5">
      <div className="w-fit text-center">
        <h4 className="text-3xl font-bold mb-2">
          {isCounterData?.CounterName || "Counter"}{" "}
          {counterOnCallToken?.LastOnCallToken ? (
            <span>({counterOnCallToken?.LastOnCallToken})</span>
          ) : (
            ""
          )}
        </h4>
      </div>
      <div className="flex justify-between items-center">
        <div className="mb-2">
          {Cookies.get("resetToken") === "true" ? <TokenReset /> : null}
          <div style={{ width: "100px" }}></div>
        </div>
        {/* Transfer button */}
        {/* { Number(tokenValue) === counterOnCallToken?.LastOnCallToken ? */}
        <div
          className={`w-fit flex justify-center gap-1   sm:gap-2 font-medium rounded-lg text-xs sm:text-sm px-3 sm:px-5 py-2 sm:py-2.5 text-center mb-2 text-white  ${
            counterOnCallToken?.LastOnCallToken ||
            (tokenValue && tokenValue > 0)
              ? "bg-[#2552EB]"
              : "bg-gray-400 cursor-not-allowed "
          } ${"lg:mr-[50%]"}
              `}
          onClick={
            counterOnCallToken?.LastOnCallToken ||
            (tokenValue && tokenValue > 0)
              ? handleTransferClick
              : null
          }
        >
          <img
            className="w-6 "
            src="/image/arrow-goes-left-right-icon.svg"
            alt="element"
          />
          Token Transfer
        </div>
      </div>
      {/* token transfer modal */}

      <Dialog
        open={openTransferToken}
        onClose={() => setOpenTransferToken(false)}
        aria-labelledby="transfer-dialog-title"
        aria-describedby="transfer-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="transfer-dialog-description"
            className="select-none flex justify-between items-center"
          >
            Select a department to transfer the token:
          </DialogContentText>

          <div className="mt-4 display: flex boxShadow: to-blue-700">
            {allDepartment?.data?.department?.department?.map((dept) => {
              // console.log("dept :", dept?.DepName);
              // allDepartment?.data?.department?.department
              const DepID = Cookies.get("DepID");
              const isCurrentDept = Number(DepID) === dept.DepID;
              return isCurrentDept ? null : (
                <Button
                  className="mt-4"
                  key={dept.DepID}
                  onClick={() => handleTransfer(dept.DepID)}
                >
                  {dept.DepName}
                </Button>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTransferToken(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <div className="flex flex-col justify-between">
            <div className="w-full">
              <h4 className="text-lg">Token No:</h4>
              <span className="text-4xl font-bold">
                {isLoading
                  ? "Loading..."
                  : `${data?.data?.CallToken} of ${data?.data?.TotalToken}`}
              </span>
            </div>
            <input
              className={`text-6xl font-bold h-16 w-full input-no-cursor bg-transparent focus:outline-none focus:outline-0 text-right`}
              value={tokenValue}
              readOnly
            />
          </div>
          <div className="grid grid-cols-3 gap-2 mt-2">
            <CounterButton num={"1"} />
            <CounterButton num={"2"} />
            <CounterButton num={"3"} />
            <CounterButton num={"4"} />
            <CounterButton num={"5"} />
            <CounterButton num={"6"} />
            <CounterButton num={"7"} />
            <CounterButton num={"8"} />
            <CounterButton num={"9"} />
            <button
              onClick={() => setTokenValue(tokenValue.toString().slice(0, -1))}
              className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 rounded-md text-center leading-50 text-base text-gray-700 m-1"
            >
              <FaBackspace />
            </button>

            <CounterButton num={"0"} />

            {Number(tokenValue) === counterOnCallToken?.LastOnCallToken ||
            (counterOnCallToken?.LastOnCallToken && !tokenValue) ? (
              <button
                className="flex items-center justify-center font-bold p-4 rounded-md leading-50 text-base text-center m-1 text-white w-50 h-50 bg-[#2552EB]"
                onClick={handleRecallToken}
              >
                Recall
              </button>
            ) : (
              <button
                onClick={() => {
                  if (!isLoadingCall) {
                    handleClickOpen();
                  }
                }}
                className="flex items-center justify-center p-4 font-bold shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-50 h-50 bg-gray-100 rounded-md text-center leading-50 text-base text-gray-700 m-1"
              >
                CALL
              </button>
            )}
          </div>
          <div className="grid grid-cols-2 gap-2 mt-4">
            <div
              onClick={() => {
                if (!isLoadingCall) {
                  handleTokenCall(1);
                }
              }}
              className={`bg-gradient-to-b from-[#2552EB] to-[#3C73F6] hover:from-[#3C73F6] hover:to-[#2552EB] transform-colors duration-300 flex flex-col items-center justify-center p-5 cursor-pointer w-full text-white text-lg gap-2`}
              disabled={isLoadingCall}
            >
              <img className="w-9" src="/image/element_1.svg" alt="element" />
              {isLoadingCall ? "Calling" : "Hold & Next"}
            </div>
            <div
              onClick={() => {
                if (!isLoadingCall) {
                  handleTokenCall(0);
                }
              }}
              className="flex flex-col items-center justify-center p-5 cursor-pointer bg-gradient-to-b from-[#358000] to-[#80C259] w-full text-white text-lg gap-2"
              disabled={isLoadingCall}
            >
              <img className="w-9 " src="/image/element_2.svg" alt="element" />
              {isLoadingCall ? "Calling" : "Next Token"}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div
              onClick={() => setHoldMenu(true)}
              className={`${
                holdMenu ? "bg-[#2552EB]" : "bg-gray-400"
              } cursor-pointer transform-all duration-300 flex justify-center gap-2 text-center w-full text white p-4 rounded-tl-3xl text-white text-2xl uppercase font-bold`}
            >
              <img className="w-9" src="/image/element_1.svg" alt="element" />
              Token on hold
            </div>
            <div
              onClick={() => setHoldMenu(false)}
              className={`${
                holdMenu ? "bg-gray-400" : "bg-[#2552EB]"
              } cursor-pointer transform-all duration-300 flex justify-center gap-2 text-center w-full text white p-4 rounded-tr-3xl text-white text-2xl uppercase font-bold`}
            >
              <img className="w-9" src="/image/element_1.svg" alt="element" />
              Pending Token
            </div>
          </div>
          {holdMenu ? (
            <Paper className="flex" sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 440 }} className="flex">
                <Table>
                  <TableBody>
                    {evenTokens?.map((evenTokens, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                              verticalAlign: "top",
                              borderRightWidth: "1px",
                            }}
                            onClick={() => handleHoldTokenCalling(evenTokens)}
                          >
                            {evenTokens.TokenValue}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    {oddTokens?.map((oddTokens, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            style={{
                              textAlign: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                              verticalAlign: "top",
                              borderLeftWidth: "1px",
                            }}
                            onClick={() => handleHoldTokenCalling(oddTokens)}
                          >
                            {oddTokens.TokenValue}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <PendingToken pendingValue={(e) => setTokenValue(e)} />
          )}
        </div>
      </div>

      <TokenCall
        open={open}
        setOpen={setOpen}
        handleTokenCall={handleTokenCall}
        isLoadingCall={isLoadingCall}
        handleTokenComplete={handleTokenComplete}
        tokenValue={counterOnCallToken?.LastOnCallToken}
      />
    </div>
  );
};
export default Counter;
