import React from "react";

const summaryReport = ({
  header,
  Tittle,
  Served,
  Transferred,
  Average,
  Peak,
  Counter,
  Unserved,
  headerNew,
  peaktime,
  endtime,
  issued,
}) => {
  const formatTo12Hour = (hour) => {
    if (hour === undefined || hour === null) return "N/A";

    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour} ${ampm}`;
  };

  return (
    <div className="relative flex gap-4 mt-2 items-start justify-center">
      <div className="flex flex-col items-center relative z-10">
        <div className="bg-gradient-to-r from-blue-500 to-blue-700 rounded-[9px] p-5 shadow-lg w-[230px] h-[200px] hover:scale-105 flex flex-col items-center text-center">
          {/* Header */}
          <span className="absolute top-7 text-xl font-medium text-white uppercase">
            {Tittle} {Served} {Transferred} {Average} {Peak} {Counter}{" "}
            {Unserved}
          </span>

          {/* Content */}
          <div className="mt-10 flex flex-col justify-center items-center">
            <h3 className="font-semibold text-white text-lg">{header}</h3>

            {peaktime?.length > 0 &&
              peaktime.slice(0, 2).map((item, i) => (
                <div key={i} >
                  <p className="font-semibold text-white text-lg">
                    Counter : {item.counterName}
                  </p>
                  <p className="font-semibold text-white text-lg">
                    Served : {item.tokensServed}
                  </p>
                </div>
              ))}

            {/* Issued Data - Showing Only 5 Pairs */}
            {endtime ? (
              <div>
                <p className="font-semibold text-white text-lg gap-2">
                  Time: {formatTo12Hour(endtime?.hour)}
                </p>
                <p className="font-semibold text-white text-lg">
                  Token Issued : {endtime?.tokensIssued}
                </p>
              </div>
            ) : null}

            <h3 className="font-semibold text-amber-100 text-lg">
              {headerNew}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default summaryReport;
